import { observer } from "mobx-react";
import { FC, useEffect, useRef, useState } from "react";
import TaskModel from "./task.model";
import styled from "styled-components";
import { Button, DatePicker, Select, message } from "antd";
import TextArea from "antd/es/input/TextArea";
import newTaskAPI from "./task.api";
import { useUserStoreContext } from "../user";
import dayjs from "dayjs";
import { useTasksStoreContext } from "./tasks.store";
import { useTranslation } from "react-i18next";
import "moment/locale/ru";
import { Option } from "antd/es/mentions";
import TaskGroup from "./taskgroup.model";

const Container = styled.div`
  background: white;
  border-radius: 8px;
  border: 1px solid #eee;
  margin-top: 49px;
  margin-bottom: 30px;
  overflow-y: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-x: hidden;
  @media (max-width: 1000px) {
    position: fixed;
    width: calc(100% - 70px);
    height: calc(100% - 280px);
  }
`;

const Title = styled.h1`
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  padding: 0;
  border: none;
  width: calc(100%);
  resize: vertical;
`;

const CreatedText = styled.p`
  margin-top: auto;
  margin-bottom: 0;
  font-size: 13px;
  text-align: center;
  color: #999;
`;

const UpdatedText = styled.p`
  margin-bottom: 0px;
  margin-top: 0px;
  font-size: 13px;
  text-align: center;
  color: #999;
`;

const CompletedText = styled.p`
  margin-bottom: 0px;
  margin-top: 0px;
  font-size: 13px;
  text-align: center;
  color: #999;
`;

const DeadlineBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  p {
    font-size: 14px;
    margin: 0;
    padding: 0;
    white-space: nowrap;
  }
  div {
    width: 100%;
  }
`;

const DescriptionBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  p {
    font-size: 14px;
    margin: 0;
    padding: 0;
  }
`;

const Textarea = styled(TextArea)`
  border-radius: 8px;
`;

const DetailTask: FC<{ task: TaskModel }> = observer(({ task }) => {
  const [text, setText] = useState<string>(task.text);
  const { setDetailTask, setTasks, tasks, taskGroups, detailTask } =
    useTasksStoreContext();
  const [description, setDescription] = useState<string | undefined>(
    task.description
  );
  const [deadline, setDeadline] = useState<Date | null>(task.deadline || null);
  const [group, setGroup] = useState<TaskGroup | null>(task.group || null);
  const titleInput: any = useRef(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const { access_token } = useUserStoreContext();
  const { t } = useTranslation("tasks");
  const handleKey = async (event: any) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (!text.trim()) {
        titleInput.current.blur();
        return setText(task.text);
      }
    }
  };
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    setText(task.text);
    setDescription(task.description || "");
    setDeadline(task.deadline || null);
    setGroup(task.group || null);
    if (task.id) {
      setIsOpen(true);
    }
  }, [task.id, task.text, task.description, task.deadline, task.group]);

  const handleEscape = (e: KeyboardEvent) => {
    if (e.key === "Escape") {
      setDetailTask({} as TaskModel);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleEscape);
    return () => document.removeEventListener("keydown", handleEscape);
  });

  const saveTask = async () => {
    task.description = description;
    task.text = text;
    task.deadline = deadline;
    task.group = group;
    if (await newTaskAPI.editTask(task, access_token)) {
      message.success(t("task_edited"));
      setDetailTask({} as TaskModel);
      setTasks(
        tasks
          .filter((e) => e.id !== task.id)
          .concat(task)
          .sort((a: TaskModel, b: TaskModel) => {
            // Если оба объекта имеют дедлайн, сортируем по дедлайну в порядке убывания
            if (b.deadline && a.deadline) {
              return (
                new Date(b.deadline).getTime() - new Date(a.deadline).getTime()
              );
            }

            // Если дедлайн отсутствует хотя бы в одном из объектов,
            // сортируем по признаку наличия дедлайна,
            // задачи без дедлайна должны идти после тех, у которых есть дедлайн
            if ((!a.deadline && b.deadline) || (a.deadline && !b.deadline)) {
              return a.deadline ? -1 : 1;
            }

            // Если оба объекта не имеют дедлайна, сортируем по id
            return a.id - b.id;
          })
      );
    }
  };

  const deleteTask = async () => {
    if (await newTaskAPI.deleteTask(task, access_token)) {
      message.success(t("task_deleted"));
      setDetailTask({} as TaskModel);
      setTasks(tasks.filter((e) => e.id !== task.id));
    }
  };

  const changeGroup = (group: string) => {
    if (group === "all") {
      setGroup({} as TaskGroup);
    } else {
      setGroup(taskGroups.filter((e) => e.id === parseInt(group))[0]);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      console.log("class", target.classList);

      if (
        containerRef.current &&
        !containerRef.current.contains(target) &&
        !target.className.match(/\bant-picker-\S+/) &&
        !target.className.match(/\bant-btn-\S+/) &&
        !target.className.match(/\bant-select-\S+/) &&
        !(target instanceof DOMTokenList) &&
        !target.closest(".ant-btn") &&
        !target.parentElement?.parentElement?.closest(".ant-picker-content")
      ) {
        if (isOpen) {
          setDetailTask({} as TaskModel);
          setIsOpen(false);
        }
        console.log("Клик вне зоны", isOpen ? "Открыт" : "Закрыт");
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, [detailTask, isOpen, setDetailTask]);

  return (
    <Container ref={containerRef}>
      <Title
        contentEditable
        ref={titleInput}
        suppressContentEditableWarning
        onInput={(event: any) => setText(event.target.innerText)}
        onKeyUp={handleKey}
        onKeyDown={(event) =>
          event.key === "Enter" ? event.preventDefault() : ""
        }
      >
        {task.text}
      </Title>
      <DeadlineBox>
        <p>{t("deadline")}:</p>
        <DatePicker
          showTime={{ format: "HH:mm" }}
          value={deadline ? dayjs(deadline) : ""}
          placeholder={t("select_date")}
          onChange={(value: any) => {
            setDeadline(value ? value["$d"] : null);
          }}
          format="DD.MM.YYYY HH:mm"
        />
      </DeadlineBox>
      <DeadlineBox>
        <p>{t("group")}: </p>
        <Select
          showSearch
          placeholder="Выберите значение"
          optionFilterProp="children"
          value={group ? group.title : "all"}
          filterOption={(input: any, option: any) =>
            option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          onChange={changeGroup}
        >
          <Option value="all">{t("all_tasks")}</Option>
          {taskGroups.map((e) => (
            <Option value={`${e.id}`} key={`${e.id}`}>
              {e.title}
            </Option>
          ))}
        </Select>
      </DeadlineBox>
      <DescriptionBox>
        <p>{t("add_note")}:</p>
        <Textarea
          placeholder={t("add_note_placeholder")}
          value={description}
          onChange={({ target }) => setDescription(target.value)}
        />
      </DescriptionBox>
      <Button type="primary" onClick={saveTask}>
        {t("save")}
      </Button>
      <Button danger onClick={deleteTask}>
        {t("delete")}
      </Button>
      <Button onClick={() => setDetailTask({} as TaskModel)}>
        {t("hide")}
      </Button>
      <CreatedText>
        {t("task_created")} {new Date(task.created_at).toLocaleString()}
      </CreatedText>
      {task.created_at !== task.updated_at && (
        <UpdatedText>
          {t("task_edited")} {new Date(task.updated_at).toLocaleString()}
        </UpdatedText>
      )}
      {task.completed && (
        <CompletedText>
          {t("task_completed")}{" "}
          {new Date(task.completed_at || Date.now()).toLocaleString()}
        </CompletedText>
      )}
    </Container>
  );
});

export default DetailTask;
