import { AuthLayout, Button, Input, config } from "../../../shared";
import { observer } from "mobx-react";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import logo from "../../../shared/brand/logo.svg";
import { AuthLink } from "../ui";
import Links from "../ui/Links";
import Inputs from "../ui/Inputs";
import Form from "../ui/Form";
import WelcomeMessage from "../ui/WelcomeMessage";
import loginApi from "./login.api";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import { useUserStoreContext } from "../../../entities/user";

const LoginPage: FC = observer(() => {
  const { t } = useTranslation("auth");
  const navigate = useNavigate();
  const { setAccessToken, setAuth, setUser } = useUserStoreContext();
  const onPressButton = async () => {
    if (!email.trim() || !password.trim()) {
      return message.error(t("form_required"));
    }
    const response = await loginApi.login(
      email,
      password,
      window.navigator.userAgent
    );
    if (response.status === "ok") {
      message.success(t(response.message.toString()));
      setAuth(true);
      setUser(response.data!.user);
      setAccessToken(response.data!.access_token);
      localStorage.refresh_token = response.data!.refresh_token;
      return navigate(`/${config.getPrefixedPath(config.prefixes.APP)}/main`);
    } else {
      message.error(t(response.message.toString()));
    }
  };

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  return (
    <AuthLayout title={t("login_page_title")}>
      <Form id="loginForm">
        <img src={logo} alt="Voronin Lab Logo" />
        <WelcomeMessage>{t("login_welcome_message")}</WelcomeMessage>
        <Inputs>
          <Input
            type="text"
            placeholder={t("login_email_text")}
            value={email}
            onChange={({ target }) => setEmail(target.value)}
          />
          <Input
            type="password"
            placeholder={t("login_password_text")}
            value={password}
            onChange={({ target }) => setPassword(target.value)}
          />
        </Inputs>
        <Button onClick={onPressButton}>{t("login_text")}</Button>
        <Links>
          <AuthLink
            to={`/${config.getPrefixedPath(config.prefixes.AUTH)}/recovery`}
          >
            {t("recovery_password")}
          </AuthLink>
          <AuthLink
            to={`/${config.getPrefixedPath(config.prefixes.AUTH)}/signup`}
          >
            {t("signup_text")}
          </AuthLink>
        </Links>
      </Form>
    </AuthLayout>
  );
});

export default LoginPage;
