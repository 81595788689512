import { PageProps } from "../../types";
import { changeLanguage } from "i18next";
import { observer } from "mobx-react";
import { FC, PropsWithChildren, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { i18n } from "../i18n";
import { useUserStoreContext } from "../../entities/user";
import { useNavigate } from "react-router-dom";
import config from "../config";
import refreshToken from "../api/refreshToken";

const Layout = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  background: #4f8be4;
  width: 100%;
  height: 100vh;
  @media (max-width: 840px) {
    grid-template-columns: 1fr;
  }
`;

const MainContainer = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  .content {
    margin-top: auto;
    margin-bottom: auto;
  }
  .lower {
    margin-bottom: 40px;
    text-align: center;
    cursor: pointer;
    font-weight: 500;
    opacity: 0.5;
    color: #6c6c84;
    transition: opacity 0.2s;
    &:hover {
      opacity: 0.8;
    }
  }
`;

const AuthLayout: FC<PropsWithChildren<PageProps>> = observer(
  ({ title, children }) => {
    const { t } = useTranslation("auth");

    const toggleLanguage = () => {
      localStorage.language = i18n.language !== "en" ? "en" : "ru";
      changeLanguage(i18n.language !== "en" ? "en" : "ru");
    };

    const { isAuth, setAccessToken, setAuth, setUser } = useUserStoreContext();
    const navigate = useNavigate();
    useEffect(() => {
      const syncRefresh = async () => {
        if (isAuth)
          return navigate(
            `/${config.getPrefixedPath(config.prefixes.APP)}/main`
          );
        if (!isAuth && localStorage.refresh_token) {
          refreshToken(localStorage.refresh_token).then((data) => {
            if (data) {
              setAccessToken(data.access_token);
              setAuth(true);
              setUser(data.user);
              window.localStorage.refresh_token = data.refresh_token;
              return navigate(
                `/${config.getPrefixedPath(config.prefixes.APP)}/main`
              );
            }
          });
        }
      };
      syncRefresh();
    }, [isAuth, navigate, setAccessToken, setUser, setAuth]);

    return (
      <Layout>
        <title>{title}</title>
        <MainContainer>
          <div className="content">{children}</div>
          <div className="lower" onClick={toggleLanguage}>
            {t("switch_language")}
          </div>
        </MainContainer>
      </Layout>
    );
  }
);

export default AuthLayout;
