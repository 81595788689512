import { observer } from "mobx-react";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import newTaskAPI from "./task.api";
import { useUserStoreContext } from "../user";
import { useTasksStoreContext } from "./tasks.store";
import TaskGroup from "./taskgroup.model";
import { Modal, Popconfirm, message } from "antd";
import { Input } from "src/shared";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear, faXmark } from "@fortawesome/free-solid-svg-icons";

const Wrapper = styled.div`
  display: flex;
  background: white;
  border: 1px solid #eee;
  border-radius: 12px;
  min-height: 50px;
  overflow: hidden;
  position: relative;
  max-width: 100%;
`;

const Container = styled.div`
  width: calc(100%);
  min-height: 50px;
  display: flex;
  padding-left: 15px;
  padding-right: 15px;
  overflow-x: auto;
  box-sizing: border-box;
  scroll-behavior: smooth;
  gap: 10px;
  position: relative;

  &::-webkit-scrollbar {
    height: 2px; /* Ширина скроллбара */
  }

  /* Фон */
  &::-webkit-scrollbar-track {
    background: transparent; /* Цвет фона скроллбара */
  }

  /* Ползунок */
  &::-webkit-scrollbar-thumb {
    background: #c9d0da;
    border-radius: 4px; /* Скругление углов ползунка */
  }

  &:hover::-webkit-scrollbar-thumb {
    border-radius: 4px; /* Скругление углов ползунка */
  }

  /* Ползунок при наведении */
  &::-webkit-scrollbar-thumb:hover {
    background: #999; /* Цвет ползунка скроллбара при наведении */
  }
`;

const Element = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  scroll-behavior: smooth;
  white-space: nowrap;
  margin-top: 2px;
  border-bottom: 2px solid transparent;
  transition: border-color 0.2s;
  font-size: 16px;
  user-select: none;
  &.selected {
    border-bottom: 2px solid #408ae9;
  }
  &:not(.selected):hover {
    border-bottom: 2px solid #ccc;
  }
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 4px;
    font-size: 12px;
    color: #747a80;
    width: 12px;
    height: 12px;

    &:nth-child(2) {
      display: none;
    }
  }

  &.canDeleted:hover {
    span:nth-child(1) {
      display: none;
    }
    span:nth-child(2) {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50px;
      background: #eee;
      width: 6px;
      height: 6px;
      padding: 3px;
      &:hover {
        background: #ddd;
      }
    }
  }
`;

const Fade = styled.div`
  position: absolute;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 25%,
    rgba(255, 255, 255, 1) 75%
  );
  width: 50px;
  height: 50px;
  z-index: 1;
  right: 47px;
`;

const SettingsGroup = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding-right: 15px;
  font-size: 16px;
  border-left: 1px solid #eee;
  padding-left: 15px;
  padding-right: 15px;
  cursor: pointer;
  user-select: none;
  &:hover {
    background: #fafafa;
  }
`;

const TaskGroups = observer(() => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isOverflowing, setIsOverflowing] = useState<boolean>(false);
  const [isScrolledToRight, setIsScrolledToRight] = useState<boolean>(false);
  const { t } = useTranslation("tasks");
  const { access_token } = useUserStoreContext();
  const [openModal, setOpenModal] = useState<boolean>(false);

  const handleWheelScroll = (event: React.WheelEvent<HTMLDivElement>) => {
    const delta = Math.sign(event.deltaY);

    if (containerRef.current) {
      containerRef.current.scrollBy({
        top: 0,
        left: delta * 100,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    function checkOverflow() {
      if (container) {
        setIsOverflowing(container.scrollWidth > container.clientWidth);
      }
    }

    checkOverflow(); // Проверяем наличие переполнения при монтировании

    function handleResize() {
      checkOverflow();
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function handleScroll() {
    const container = containerRef.current;
    if (container) {
      const scrollLeft = container.scrollLeft;
      const scrollWidth = container.scrollWidth;
      const clientWidth = container.clientWidth;
      const isAtRightEdge = scrollWidth - scrollLeft - clientWidth < 50;
      setIsScrolledToRight(isAtRightEdge);
    }
  }

  const {
    tasks,
    taskGroups,
    groupsLoaded,
    setGroupsLoaded,
    selectedTaskGroup,
    setSelectedTaskGroup,
    setTaskGroups,
  } = useTasksStoreContext();

  useEffect(() => {
    if (!groupsLoaded) {
      newTaskAPI.getGroups(access_token).then((e) => {
        setTaskGroups(e);
        setSelectedTaskGroup("all");
        setGroupsLoaded(true);
      });
    }
  }, [
    access_token,
    groupsLoaded,
    setGroupsLoaded,
    setSelectedTaskGroup,
    setTaskGroups,
  ]);

  const changeGroup = (e: "all" | "today" | TaskGroup) => {
    setSelectedTaskGroup(e);
  };

  const deleteTaskGroup = (id: number) => {
    newTaskAPI.deleteTaskGroup(id, access_token);
    newTaskAPI.getGroups(access_token).then((e) => {
      setTaskGroups(e);
      setSelectedTaskGroup("all");
      setGroupsLoaded(true);
    });
  };

  const [title, setTitle] = useState<string>("");
  const saveGroup = () => {
    if (!title) {
      return message.error(t("enter_title_group"));
    }
    newTaskAPI.createGroup(title, access_token).then((data) => {
      setTaskGroups(taskGroups.concat(data));
      message.success(t("group_created"));
      setTitle("");
      setOpenModal(false);
    });
  };

  return (
    <Wrapper>
      <Modal
        title="Добавление группы"
        open={openModal}
        onCancel={() => setOpenModal(false)}
        onOk={saveGroup}
      >
        <Input
          placeholder="Название группы"
          value={title}
          onChange={({ target }) => setTitle(target.value)}
        />
      </Modal>
      <Container
        ref={containerRef}
        onWheel={handleWheelScroll}
        onScroll={handleScroll}
      >
        <Element
          className={selectedTaskGroup === "all" ? "selected" : ""}
          onClick={() => changeGroup("all")}
        >
          {t("all_tasks")}{" "}
          <span>{tasks.filter((e) => !e.completed).length || ""}</span>
        </Element>
        <Element
          className={selectedTaskGroup === "today" ? "selected" : ""}
          onClick={() => changeGroup("today")}
        >
          {t("today_tasks")}{" "}
          <span>
            {tasks.filter(
              (e) =>
                !e.completed &&
                e.deadline &&
                new Date(e.deadline).toDateString() ===
                  new Date().toDateString()
            ).length || ""}
          </span>
        </Element>
        {groupsLoaded &&
          taskGroups.length > 0 &&
          taskGroups.map((e) => (
            <Element
              className={`canDeleted ${
                selectedTaskGroup === e ? "selected" : ""
              }`}
              key={e.id}
              onClick={() => changeGroup(e)}
            >
              {e.title}{" "}
              {/* {e.tasks?.filter((e) => !e.completed).length > 0 && ( */}
              <>
                <span>{e.tasks?.filter((e) => !e.completed).length || 0}</span>
                <Popconfirm
                  title="Удалить группу"
                  description="Вы уверены что хотите удалить группу?"
                  okText="Да"
                  cancelText="Нет"
                  onConfirm={() => deleteTaskGroup(e.id)}
                >
                  <span>
                    <FontAwesomeIcon icon={faXmark} size={"2xs"} />
                  </span>
                </Popconfirm>
              </>
              {/* )} */}
            </Element>
          ))}
      </Container>
      {isOverflowing && !isScrolledToRight ? <Fade /> : <></>}
      <SettingsGroup onClick={() => setOpenModal(true)}>
        <FontAwesomeIcon icon={faGear} />
      </SettingsGroup>
    </Wrapper>
  );
});

export default TaskGroups;
