import {
  SidebarTabs,
  useSidebarStoreContext,
} from "../../shared/stores/sidebar.store";
import MainLayout from "../../shared/ui/MainLayout";
import { observer } from "mobx-react";
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";

const WalletPage: FC = observer(() => {
  const { t } = useTranslation("wallet");
  const { setActiveTab } = useSidebarStoreContext();
  useEffect(() => {
    setActiveTab(SidebarTabs.WALLET);
  });
  return <MainLayout title={t("page_title")}></MainLayout>;
});

export default WalletPage;
