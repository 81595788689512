const tasksMessages = {
  ru: {
    page_title: "Задачи",
    your_tasks: "Ваши задачи",
    create_task: "Создать задачу",
    create_task_group: "Создать задачу в группе {{title}}",
    input_task_text: "Введите текст задачи",
    deadline: "Срок выполнения",
    add_note: "Примечание",
    select_date: "Выберите дату",
    add_note_placeholder: "Примечание к задаче",
    save: "Сохранить",
    delete: "Удалить",
    hide: "Скрыть",
    task_created: "Задача создана",
    task_edited: "Задача изменена",
    task_completed: "Задача выполнена",
    task_deleted: "Задача удалена",
    all_tasks: "Все задачи",
    add_task_group: "Добавить группу",
    group_clear: { first: "В группе ", last: " пока нет задач" },
    group: "Группа",
    suggest_add_task: "Для добавления задачи нажмите Enter",
    overdue: "Просрочено на",
    completed_advance: "Выполнено заранее",
    more_left: "Ещё",
    years: {
      one: "год",
      two: "года",
      many: "лет",
    },
    months: { one: "месяц", two: "месяца", many: "месяцев" },
    days: { one: "день", two: "дня", many: "дней" },
    hours: { one: "час", two: "часа", many: "часов" },
    minutes: { one: "минута", two: "минуты", many: "минут" },
    enter_title_group: "Укажите название группы",
    group_created: "Группа создана!",
    today_tasks: "Сегодня",
  },
  en: {
    page_title: "Tasks",
    your_tasks: "Your tasks",
    create_task: "Add task",
    create_task_group: "Create task in group {{title}}",
    input_task_text: "Enter task text",
    deadline: "Deadline",
    add_note: "Add note",
    select_date: "Select date",
    add_note_placeholder: "Note on the problem",
    save: "Save",
    delete: "Delete",
    hide: "Hide",
    task_created: "Task created",
    task_edited: "Task edited",
    task_completed: "Task completed",
    task_deleted: "Task deleted",
    all_tasks: "All tasks",
    add_task_group: "Add group",
    group_clear: { first: "There are no tasks in the ", last: " group yet" },
    group: "Group",
    suggest_add_task: "To add a task, press Enter",
    overdue: "Overdue for",
    completed_advance: "Completed in advance",
    more_left: "More left",
    years: {
      one: "year",
      two: "years",
      many: "years",
    },
    months: { one: "month", two: "months", many: "months" },
    days: { one: "day", two: "days", many: "days" },
    hours: { one: "hour", two: "hours", many: "hours" },
    minutes: { one: "minute", two: "minutes", many: "minutes" },
    enter_title_group: "Enter title group",
    group_created: "Group created!",
    today_tasks: "Today",
  },
};

export default tasksMessages;
