import { PageProps } from "../../types";
import { observer } from "mobx-react";
import { FC, PropsWithChildren, useEffect, useState } from "react";
import styled from "styled-components";
import { useUserStoreContext } from "../../entities/user";
import { useNavigate } from "react-router-dom";
import config from "../config";
import refreshToken from "../api/refreshToken";
import Sidebar from "./Sidebar";
import background from "../images/background.png";
import { ConfigProvider } from "antd";
import ruRU from "antd/es/locale/ru_RU";
import enEN from "antd/es/locale/en_US";
import i18n from "i18next";
import newTaskAPI from "src/entities/task/task.api";
import { useTasksStoreContext } from "src/entities/task/tasks.store";
import Preloader from "./Preloader";

const Layout = styled.div``;

const MainContainer = styled.div`
  display: flex;
  height: 100vh;
  background: url(${background});
  background-size: cover;
  box-sizing: border-box;
  overflow-x: hidden;
  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

const Content = styled.div`
  padding: 15px;
  width: calc(100% - 351px);
  height: calc(100vh - 30px);
  @media (max-width: 1000px) {
    width: calc(100% - 30px);
    height: calc(100vh - 75px);
  }
`;

const MainLayout: FC<PropsWithChildren<PageProps>> = observer(
  ({ title, children }) => {
    const {
      isAuth,
      setAccessToken,
      setUser,
      setAuth,
      tokenLoaded,
      setTokenLoaded,
    } = useUserStoreContext();
    const [tokenLoader, setTokenLoader] = useState<boolean>(tokenLoaded);
    const navigate = useNavigate();
    const { setTasks } = useTasksStoreContext();
    useEffect(() => {
      if (!isAuth && !localStorage.refresh_token)
        return navigate(
          `/${config.getPrefixedPath(config.prefixes.AUTH)}/login`
        );
      if (!isAuth && localStorage.refresh_token) {
        refreshToken(localStorage.refresh_token).then((data: any) => {
          if (data) {
            setTokenLoaded(true);
            setTokenLoader(true);
            setAuth(true);
            setAccessToken(data.access_token);
            setUser(data.user);
            window.localStorage.refresh_token = data.refresh_token;
            newTaskAPI.getSelfTasks(data.access_token).then((e) => {
              setTasks(e);
              console.log(e);
            });
          } else if (!data) {
            return navigate(
              `/${config.getPrefixedPath(config.prefixes.AUTH)}/login`
            );
          }
        });
      }
    });


    if (!tokenLoaded && !tokenLoader && !isAuth) {
      return <Preloader />;
    }

    return (
      <ConfigProvider locale={i18n.language === "ru" ? ruRU : enEN}>
        <Layout>
          <title>{title}</title>
          <MainContainer>
            <Sidebar />
            <Content>{children}</Content>
          </MainContainer>
        </Layout>
      </ConfigProvider>
    );
  }
);

export default MainLayout;
