import { RouteObject } from "react-router-dom";
import { config } from "../../shared";
import LoginPage from "./login/login.page";
import SignupPage from "./sign-up/signup.page";
import RecoveryPage from "./recovery/recovery.page";

const prefix = config.getPrefixedPath(config.prefixes.AUTH);

const router: RouteObject[] = [
  {
    path: `${prefix}/signup`,
    element: <SignupPage />,
  },
  {
    path: `${prefix}/login`,
    element: <LoginPage />,
  },
  {
    path: `${prefix}/recovery`,
    element: <RecoveryPage />,
  },
];

export default router;
