import { observer } from "mobx-react";
import { FC, PropsWithChildren } from "react";
import styled from "styled-components";
import DetailTask from "./DetailTask";
import { useTasksStoreContext } from "./tasks.store";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  height: calc(100vh - 30px);
  @media (max-width: 1000px) {
    height: calc(100vh - 90px);
  }
`;

const TaskContainer = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: 100% 0px;
  transition: grid-template-columns 0.2s;
  &.withDetail {
    grid-template-columns: calc(100% - 350px) 350px;
    @media (max-width: 1000px) {
      grid-template-columns: 100% 0px;
    }
  }
`;

const TasksLayout: FC<PropsWithChildren> = observer(({ children }) => {
  const { detailTask } = useTasksStoreContext();
  return (
    <TaskContainer
      className={Object.keys(detailTask).length > 0 ? "withDetail" : ""}
    >
      <Container>{children}</Container>
      {Object.keys(detailTask).length > 0 && <DetailTask task={detailTask} />}
    </TaskContainer>
  );
});

export default TasksLayout;
