import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import { globalRouter } from "./shared";
import { I18nextProvider } from "react-i18next";
import { i18n } from "./shared";
import { Provider } from "mobx-react";
import { RootStore } from "./store";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import "dayjs/locale/ru";
import '@vkontakte/vkui/dist/vkui.css';

library.add(fas);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const router = createBrowserRouter([
  { path: "/", element: <Navigate to={"/app/main"} /> },
  ...globalRouter,
]);

root.render(
  <Provider rootStore={new RootStore()}>
    <I18nextProvider i18n={i18n}>
      <RouterProvider router={router} />
    </I18nextProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
