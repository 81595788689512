import authApi from "./authApi";
import { CHResponse, RefreshTokenResponse } from "../../types";

export default async function refreshToken(
  refresh_token: string
): Promise<RefreshTokenResponse | undefined | null> {
  const response = await authApi
    .post<CHResponse<RefreshTokenResponse>>("/refreshtoken", {
      refresh_token,
      agent: window.navigator.userAgent,
    })
    .catch(() => null);
  return response?.data.response;
}
