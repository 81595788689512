import { FC } from "react";
import styled from "styled-components";
import { useUserStoreContext } from "./user.store";
import { Avatar } from "@vkontakte/vkui";
import { Link } from "react-router-dom";
import { config } from "src/shared";

const UserContainer = styled.div`
  width: calc(100% - 15px);
  border-radius: 8px;
  padding-left: 15px;
  background: white;
  border: 1px solid #eee;
  margin-top: auto;
  display: flex;
  padding-top: 15px;
  padding-bottom: 15px;
  align-items: center;
  gap: 15px;
`;

// const Avatar = styled.img`
//   width: 42px;
//   height: 42px;
//   border-radius: 90px;
//   background: #eee;
// `;

const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const ProfileLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;

const Name = styled.p`
  margin: 0;
  padding: 0;
  font-weight: 500;
  font-size: 14px;
`;
const Email = styled.p`
  margin: 0;
  padding: 0;
  font-size: 14px;
  color: #999;
`;

const UserSidebar: FC = () => {
  const { user } = useUserStoreContext();
  return (
    <UserContainer>
      <Avatar
        size={44}
        initials={`${user.first_name[0]}${user.last_name[0]}`}
        gradientColor={"blue"}
      />
      <UserInfo>
        <ProfileLink to={`/${config.getPrefixedPath(`${config.prefixes.USER}/${user.short_link}`)}`}>
          <Name>
            {user.first_name} {user.last_name}
          </Name>
        </ProfileLink>
        <Email>{user.email}</Email>
      </UserInfo>
    </UserContainer>
  );
};

export default UserSidebar;
