export const GLOBAL_APP_PREFIX = "app";

export enum prefixes {
  APP = "",
  AUTH = "/auth",
  WALLET = "/wallet",
  TASKS = "/tasks",
  USER = "/user",
}

export function getPrefixedPath(suffix: string): string {
  return `${GLOBAL_APP_PREFIX}${suffix}`;
}
