import styled from "styled-components";

const Input = styled.input`
  width: calc(100% - 17px);
  height: 38px;
  border: 1px solid #e9e9e9;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05);
  font-family: "Inter", sans-serif;
  font-size: 14px;
  border-radius: 8px;
  padding-left: 15px;
  &::placeholder {
    transition: opacity 0.3s;
  }
  &:focus {
    &::placeholder {
      opacity: 0.6;
    }
  }
`;

export default Input;
