import { observer } from "mobx-react";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { UserModel, useUserStoreContext } from "src/entities/user";
import { userApi } from "src/shared";
import { SidebarTabs, useSidebarStoreContext } from "src/shared/stores/sidebar.store";
import MainLayout from "src/shared/ui/MainLayout";
import PageTitle from "src/shared/ui/PageTitle";

const UserPage: FC = observer(() => {
  const params = useParams();
  const [currentUser, setCurrentUser] = useState<UserModel>();
  const { access_token } = useUserStoreContext();
  const [userLoaded, setUserLoaded] = useState<boolean>(false);

  const { t } = useTranslation("user");
  const { setActiveTab } = useSidebarStoreContext();
  useEffect(() => {
    setActiveTab(SidebarTabs.UNDEFINED);
  });

  useEffect(() => {
    setUserLoaded(false);
  }, [params]);

  useEffect(() => {
    if (access_token) {
      if (!userLoaded) {
        userApi
          .get(`/${params.userId}`, {
            headers: { Authorization: `Bearer ${access_token}` },
          })
          .then((user) => {
            setUserLoaded(true);
            setCurrentUser(user.data);
          })
          .catch((e) => {
            setUserLoaded(true);
          });
      }
    }
    console.log(params, params.userId);
  }, [params, access_token, userLoaded]);

  if (!userLoaded) {
    return (
      <MainLayout title={t("loading")}>
        <PageTitle>{t("loading")}</PageTitle>
      </MainLayout>
    );
  }

  if (!currentUser) {
    return (
      <MainLayout title={t("user_not_found")}>
        <PageTitle>{t("user_not_found")}</PageTitle>
      </MainLayout>
    );
  }

  return (
    <MainLayout title={`${currentUser.first_name} ${currentUser.last_name}`}>
      <PageTitle>
        {currentUser.first_name} {currentUser.last_name}
      </PageTitle>
    </MainLayout>
  );
});

export default UserPage;
