import { config } from "../../shared";
import { RouteObject } from "react-router-dom";
import MainPage from "./main.page";

const prefix = config.getPrefixedPath(config.prefixes.APP);

const mainRouter: RouteObject[] = [
  {
    path: `${prefix}/main`,
    element: <MainPage />,
  },
];

export default mainRouter;
