import { AuthLayout, Button, Input, config } from "../../../shared";
import { observer } from "mobx-react";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import logo from "../../../shared/brand/logo.svg";
import { AuthLink } from "../ui";
import Links from "../ui/Links";
import Inputs from "../ui/Inputs";
import DualInputs from "../ui/DualInputs";
import WelcomeMessage from "../ui/WelcomeMessage";
import Form from "../ui/Form";
import signupApi from "./signup.api";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import { useUserStoreContext } from "../../../entities/user";

const SignupPage: FC = observer(() => {
  const { t } = useTranslation("auth");

  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const navigate = useNavigate();
  const { setUser, setAuth, setAccessToken } = useUserStoreContext();

  const onPressButton = async () => {
    if (
      !firstName.trim() ||
      !lastName.trim() ||
      !email.trim() ||
      !password.trim()
    ) {
      return message.error(t("form_required"));
    }
    const response = await signupApi.register(
      firstName,
      lastName,
      email,
      password
    );
    if (response.status === "error") {
      message.error(t(response.message.toString()));
    } else {
      message.success(t(response.message.toString()));
      setAuth(true);
      setUser(response.user);
      setAccessToken(response.access_token);
      return navigate(`/${config.getPrefixedPath(config.prefixes.APP)}/main`);
    }
  };

  return (
    <AuthLayout title={t("signup_page_title")}>
      <Form id="signupForm">
        <img src={logo} alt="Voronin Lab Logo" />
        <WelcomeMessage>{t("signup_welcome_message")}</WelcomeMessage>
        <Inputs>
          <DualInputs>
            <Input
              type="text"
              placeholder={t("signup_first_name_text")}
              value={firstName}
              onChange={({ target }) => setFirstName(target.value)}
            />
            <Input
              type="text"
              placeholder={t("signup_last_name_text")}
              value={lastName}
              onChange={({ target }) => setLastName(target.value)}
            />
          </DualInputs>
          <Input
            type="text"
            placeholder={t("signup_email_text")}
            value={email}
            onChange={({ target }) => setEmail(target.value)}
          />
          <Input
            type="password"
            placeholder={t("signup_password_text")}
            value={password}
            onChange={({ target }) => setPassword(target.value)}
          />
        </Inputs>
        <Button onClick={onPressButton}>{t("signup_text")}</Button>
        <Links>
          <AuthLink
            to={`/${config.getPrefixedPath(config.prefixes.AUTH)}/login`}
          >
            {t("signup_login_text")}
          </AuthLink>
        </Links>
      </Form>
    </AuthLayout>
  );
});

export default SignupPage;
