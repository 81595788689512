import styled from "styled-components";

const WelcomeMessage = styled.h1`
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  margin: 0;
  padding: 0;
  color: #333;
`;

export default WelcomeMessage;