const sidebarMessages = {
  ru: {
    desktop: "Рабочий стол",
    wallet: "Кошелек",
    tasks: "Задачи",
    users: "Пользователи",
    projects: "Проекты",
    messages: "Сообщения",
    notes: "Заметки",
    switch_language: "English"
  },
  en: {
    desktop: "Desktop",
    wallet: "Wallet",
    tasks: "Tasks",
    users: "Users",
    projects: "Projects",
    messages: "Messages",
    notes: "Notes",
    switch_language: "Русский"
  },
};

export default sidebarMessages;
