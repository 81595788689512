import { TokenModel, UserModel } from "../../../entities/user";
import { authApi } from "../../../shared";
import { CHResponse } from "../../../types";

class SignupApi {
  async register(
    first_name: string,
    last_name: string,
    email: string,
    password: string,
    agent: string = "WebPanel"
  ) {
    let message: string | TokenModel,
      status: "error" | "ok",
      user: UserModel = {} as UserModel,
      access_token: string = "";
    try {
      const response = await authApi.post<CHResponse<TokenModel>>("register", {
        first_name,
        last_name,
        email,
        password,
        agent,
      });
      if (response.status > 300) {
        status = "error";
        message = response.data.response;
      } else {
        status = "ok";
        message = "success_signup";
        localStorage.refresh_token = response.data.response.refresh_token;
        user = response.data.response.user;
        access_token = response.data.response.access_token;
      }
    } catch (e: any) {
      message = e.response.data.response;
      status = "error";
    }
    return { status, message, user, access_token };
  }
}
const signupApi = new SignupApi();
export default signupApi;
