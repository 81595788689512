import { TokenModel } from "../../../entities/user";
import { authApi } from "../../../shared";
import { CHResponse } from "../../../types";

class LoginApi {
  async login(email: string, password: string, agent: string = "WebPanel") {
    let message: string | TokenModel = "";
    let status: "error" | "ok" = "ok";
    let data;
    try {
      const response = await authApi.post<CHResponse<TokenModel>>(`login`, {
        email,
        password,
        agent,
      });
      if (response.data.type === "ok") {
        message = "success_login";
        status = "ok";
        data = response.data.response;
      } else {
        status = "error";
        message = response.data.response;
      }
    } catch (e: any) {
      console.log(e);
      message = e.response.data.response;
      status = "error";
    }
    return { status, message, data };
  }
}
const loginApi = new LoginApi();
export default loginApi;
