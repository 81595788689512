import styled from "styled-components";

const PageTitle = styled.h1`
  margin: 0;
  padding: 0;
  font-size: 28px;
  font-weight: 600;
  color: #353557;
`;

export default PageTitle;
