import { observer } from "mobx-react";
import styled from "styled-components";
import logo from "../brand/logo.svg";

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Logo = styled.div`
  display: flex;
  gap: 40px;
  color: #1e2a50;
  align-items: center;
  font-weight: bold;
  margin-bottom: 30px;
  flex-direction: column;
  img {
    border: 1px solid #eee;
    border-radius: 8px;
    width: 128px;
    height: 128px;
  }
  div {
    display: flex;
    flex-direction: column;
    p {
      margin: 0;
      padding: 0;
      font-size: 2.5em;
    }
  }
`;

const Subtext = styled.p`
  font-size: 1.1em;
  text-align: center;
  line-height: 1.43;
`;

const Preloader = observer(() => {
  return (
    <Wrapper>
      <title>Voronin Lab / Загрузка</title>
      <Logo>
        <img src={logo} alt="Voronin Lab Logotype" />
        <div>
          <p>Voronin Lab</p>
        </div>
      </Logo>
      <Subtext>Пожалуйста, подождите, мы загружаем данные</Subtext>
    </Wrapper>
  );
});

export default Preloader;
