import styled from "styled-components";

const Button = styled.button`
  height: 48px;
  border-radius: 8px;
  background: #4b7dff;
  color: white;
  font-size: 14px;
  font-weight: 500;
  font-family: Inter, sans-serif;
  border: none;
  cursor: pointer;
  transition: background 0.2s;
  &:hover {
    background: #2f6aff;
  }
  &:active {
    background: #285fe9;
  }
  &:disabled {
    background: #72798a;
    cursor: default;
  }
`;

export default Button;
