import { makeAutoObservable } from "mobx";
import { UserStore } from "./entities/user";
class RootStore {
  userStore: UserStore;
  constructor() {
    makeAutoObservable(this);
    this.userStore = new UserStore();
  }
}

export { RootStore };
