import { action, makeObservable, observable } from "mobx";
import UserModel from "./user.model";
import { createContext, useContext } from "react";

export class UserStore {
  isAuth: boolean = false;
  user: UserModel = {} as UserModel;
  access_token: string = "";
  tokenLoaded: boolean = false;

  constructor() {
    makeObservable(this, {
      isAuth: observable,
      access_token: observable,
      user: observable,
      tokenLoaded: observable,
      setTokenLoaded: action.bound,
      setAuth: action.bound,
      setUser: action.bound,
      setAccessToken: action.bound,
    });
  }

  setTokenLoaded(loaded: boolean) {
    this.tokenLoaded = loaded;
  }

  setAuth(auth: boolean) {
    this.isAuth = auth;
  }

  setUser(userModel: UserModel) {
    this.user = userModel;
  }

  setAccessToken(access_token: string) {
    this.access_token = access_token;
  }
}

export const userStore = new UserStore();
export const userStoreContext = createContext(userStore);
export const useUserStoreContext = () => useContext(userStoreContext);
