import { Link } from "react-router-dom";
import styled from "styled-components";

const NewLink = styled(Link)`
  display: block;
  text-decoration: none;
  color: #6C6C84;
  font-size: 14px;
  transition: color 0.2s;
  font-weight: 500;
  &:hover {
    color: #4b7dff;
  }
`;

export default NewLink;
