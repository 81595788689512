const mainMessages = {
  ru: {
    page_title: "Рабочий стол",
  },
  en: {
    page_title: "Desktop",
  },
};

export default mainMessages;
