const userMessages = {
  ru: {
    user_not_found: "Пользователь не найден",
    loading: "Загрузка",
  },
  en: {
    user_not_found: "User not found",
    loading: "Loading",
  },
};

export default userMessages;