import { observer } from "mobx-react";
import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import TaskGroup from "src/entities/task/taskgroup.model";
import TaskLine from "src/entities/task/taskline";
import taskApi from "src/shared/api/taskApi";
import { CHResponse } from "src/types";
import styled from "styled-components";

const Title = styled.h1`
  text-align: center;
  font-size: 2em;
  font-weight: 500;
  margin: 0;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const Container = styled.div`
  max-width: 780px;
  margin: 0 auto;
  height: calc(100vh - 120px);
  background: #eeeeee;
  padding: 20px;
  border-radius: 20px;
  display: flex;
  gap: 10px;
  flex-direction: column;
  overflow-y: auto;
  box-sizing: border-box;
  @media (max-width: 1000px) {
    max-width: 95%;
  }
  &::-webkit-scrollbar {
    width: 6px; /* Ширина скроллбара */
  }

  /* Фон */
  &::-webkit-scrollbar-track {
    background: transparent; /* Цвет фона скроллбара */
  }

  /* Ползунок */
  &::-webkit-scrollbar-thumb {
    background: #ccc; /* Цвет ползунка скроллбара */
    border-radius: 4px; /* Скругление углов ползунка */
  }

  /* Ползунок при наведении */
  &::-webkit-scrollbar-thumb:hover {
    background: #999; /* Цвет ползунка скроллбара при наведении */
  }
`;

const PublicTasksPage: FC = observer(() => {
  const { groupId } = useParams();
  const [groups, setGroups] = useState<TaskGroup>();
  const [groupLoaded, setGroupLoaded] = useState<boolean>(false);
  const [groupFounded, setGroupFounded] = useState<boolean>(false);
  useEffect(() => {
    if (!groupLoaded) {
      taskApi
        .get<CHResponse<TaskGroup>>(`/getPublicGroup?groupId=${groupId}`)
        .then((e) => {
          setGroups(e.data.response);
          setGroupLoaded(true);
          setGroupFounded(true);
        })
        .catch(() => {
          setGroupLoaded(true);
          setGroupFounded(false);
        });
    }
  });
  if (!groupLoaded) {
    return <Title>Загрузка...</Title>;
  }
  if (!groupFounded || !groups) {
    return <Title>Группа не найдена или непубличная</Title>;
  }
  return (
    <>
      <Title>{groups.title}</Title>
      <Container>
        {groups.tasks &&
          groups.tasks.map((e) => <TaskLine onlyView task={e} key={e.id} />)}
      </Container>
    </>
  );
});

export default PublicTasksPage;
