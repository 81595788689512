import taskApi from "../../shared/api/taskApi";
import { message } from "antd";
import TaskModel from "./task.model";

class TaskAPI {
  async create(text: string, token: string, group?: number) {
    const response = await taskApi.post(
      "/create",
      { text, group },
      { headers: { Authorization: `Bearer ${token}` } }
    );
    if (response.status < 300) {
      return response.data.response;
    } else {
      message.error("Произошла ошибка");
      console.log(response.data);
    }
  }

  async deleteTaskGroup(id: number, token: string) {
    const response = await taskApi.delete(`/deleteGroup/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (response.status < 300) {
      return response.data.response;
    } else {
      message.error("Произошла ошибка");
      console.log(response.data);
    }
  }

  async getSelfTasks(token: string) {
    const response = await taskApi.get("/self", {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (response.status < 300) {
      return response.data.response.tasks;
    } else {
      message.error("Произошла ошибка");
      console.log(response.data);
    }
  }

  async completeTask(task: TaskModel, token: string) {
    const response = await taskApi.patch(
      "/edit",
      { id: task.id, completed: task.completed },
      { headers: { Authorization: `Bearer ${token}` } }
    );
    if (response.status < 300) {
      return response.data.response;
    } else {
      message.error("Произошла ошибка");
      console.log(response.data);
    }
  }

  async editTask(task: TaskModel, token: string) {
    const response = await taskApi.patch("/edit", task, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (response.status < 300) {
      return response.data.response;
    } else {
      message.error("Произошла ошибка");
      console.log(response.data);
    }
  }

  async deleteTask(task: TaskModel, token: string) {
    const response = await taskApi.delete(`/delete/${task.id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (response.status < 300) {
      return response.data.response;
    } else {
      message.error("Произошла ошибка");
      console.log(response.data);
    }
  }

  async getGroups(token: string) {
    const response = await taskApi.get("/getGroups", {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (response.status < 300) {
      return response.data.response;
    } else {
      message.error("Произошла ошибка");
      console.log(response.data);
    }
  }

  async createGroup(title: string, token: string) {
    const response = await taskApi.post(
      "/createGroup",
      {
        title,
      },
      { headers: { Authorization: `Bearer ${token}` } }
    );
    if (response.status < 300) {
      return response.data.response;
    } else {
      message.error("Произошла ошибка");
      console.log(response.data);
    }
  }
}
const newTaskAPI = new TaskAPI();
export default newTaskAPI;
