import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import authMessages from "../../pages/auth/auth.i18n";
import mainMessages from "../../pages/main/main.i18n";
import sidebarMessages from "./sidebar.i18n";
import walletMessages from "../../pages/wallet/wallet.i18n";
import tasksMessages from "../../pages/tasks/tasks.i18n";
import userMessages from "../../pages/user/user.i18n";

let lang = "ru";
if (localStorage.language) {
  lang = localStorage.language;
} else {
  localStorage.language = lang;
}

i18n.use(initReactI18next).init({
  resources: {
    en: {
      auth: authMessages.en,
      main: mainMessages.en,
      wallet: walletMessages.en,
      tasks: tasksMessages.en,
      sidebar: sidebarMessages.en,
      user: userMessages.en,
    },
    ru: {
      auth: authMessages.ru,
      main: mainMessages.ru,
      wallet: walletMessages.ru,
      tasks: tasksMessages.ru,
      sidebar: sidebarMessages.ru,
      user: userMessages.ru,
    },
  },
  lng: lang,
  interpolation: {
    escapeValue: false, // необходимо для форматирования строк
  },
});

export default i18n;
