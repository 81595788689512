import { config } from "../../shared";
import { RouteObject } from "react-router-dom";
import TasksPage from "./tasks.page";
import PublicTasksPage from "./publictasks.page";

const prefix = config.getPrefixedPath(config.prefixes.TASKS);

const walletRouter: RouteObject[] = [
  {
    path: `${prefix}`,
    element: <TasksPage />,
  },
  {
    path: `${prefix}/public/:groupId`,
    element: <PublicTasksPage />
  }
];

export default walletRouter;
