import { action, makeObservable, observable } from "mobx";
import { createContext, useContext } from "react";

export enum SidebarTabs {
  UNDEFINED,
  DESKTOP,
  WALLET,
  TASKS,
  USERS,
  PROJECTS,
  MESSAGES,
  NOTES
}

export class SidebarStore {
  activeTab: SidebarTabs = SidebarTabs.UNDEFINED;
  constructor() {
    makeObservable(this, {
      activeTab: observable,
      setActiveTab: action.bound,
    });
  }

  setActiveTab(tab: SidebarTabs) {
    this.activeTab = tab;
  }
}

export const sidebarStore = new SidebarStore();
export const sidebarStoreContext = createContext(sidebarStore);
export const useSidebarStoreContext = () => useContext(sidebarStoreContext);
