import { observer } from "mobx-react";
import { FC } from "react";
import { useTasksStoreContext } from "./tasks.store";
import styled from "styled-components";
import TaskLine from "./taskline";
import { useTranslation } from "react-i18next";

const Table = styled.div`
  width: calc(100%);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding-right: 5px;
  gap: 10px;
  height: 100%;
  &::-webkit-scrollbar {
    width: 6px; /* Ширина скроллбара */
  }

  /* Фон */
  &::-webkit-scrollbar-track {
    background: transparent; /* Цвет фона скроллбара */
  }

  /* Ползунок */
  &::-webkit-scrollbar-thumb {
    background: #ccc; /* Цвет ползунка скроллбара */
    border-radius: 4px; /* Скругление углов ползунка */
  }

  /* Ползунок при наведении */
  &::-webkit-scrollbar-thumb:hover {
    background: #999; /* Цвет ползунка скроллбара при наведении */
  }
`;

const EmptyTable = styled.p`
  text-align: center;
  opacity: 1;
  color: #66798b;
  margin-top: auto;
  margin-bottom: auto;

  span {
    font-weight: 500;
  }
`;

const TaskTable: FC = observer(() => {
  const { t } = useTranslation("tasks");
  const { tasks, selectedTaskGroup } = useTasksStoreContext();
  return (
    <Table>
      {selectedTaskGroup === "all" ? (
        tasks.length > 0 ? (
          tasks.map((e) => <TaskLine task={e} key={e.id} />)
        ) : (
          <EmptyTable>Вы пока не добавили ни одной задачи</EmptyTable>
        )
      ) : selectedTaskGroup === "today" ? (
        tasks.filter(
          (todayTasks) =>
            todayTasks.deadline &&
            new Date(todayTasks.deadline).toDateString() ===
              new Date().toDateString()
        ).length > 0 ? (
          tasks
            .filter(
              (todayTasks) =>
                todayTasks.deadline &&
                new Date(todayTasks.deadline).toDateString() ===
                  new Date().toDateString()
            )
            .map((e) => <TaskLine task={e} key={e.id} />)
        ) : (
          <EmptyTable>На сегодня у Вас нет задач</EmptyTable>
        )
      ) : selectedTaskGroup.tasks?.length > 0 ? (
        selectedTaskGroup.tasks?.map((e) => <TaskLine task={e} key={e.id} />)
      ) : (
        <EmptyTable>
          {t("group_clear.first")}
          <span>{selectedTaskGroup.title}</span>
          {t("group_clear.last")}
        </EmptyTable>
      )}
    </Table>
  );
});

export default TaskTable;
