import CreateTaskField from "src/entities/task/CreateTaskField";
import TaskTable from "src/entities/task/tasktable";
import {
  SidebarTabs,
  useSidebarStoreContext,
} from "../../shared/stores/sidebar.store";
import MainLayout from "../../shared/ui/MainLayout";
import PageTitle from "../../shared/ui/PageTitle";
import { observer } from "mobx-react";
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import TasksLayout from "src/entities/task/TasksLayout";
import TaskGroups from "src/entities/task/TaskGroups";

const TasksPage: FC = observer(() => {
  const { t } = useTranslation("tasks");
  const { setActiveTab } = useSidebarStoreContext();
  useEffect(() => {
    setActiveTab(SidebarTabs.TASKS);
  });
  return (
    <MainLayout title={t("page_title")}>
      <TasksLayout>
        <PageTitle>{t("your_tasks")}</PageTitle>
        <TaskGroups />
        <TaskTable />
        <CreateTaskField />
      </TasksLayout>
    </MainLayout>
  );
});

export default TasksPage;
