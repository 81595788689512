import axios from "axios";
import config from "../config";
import refreshToken from "./refreshToken";

const userApi = axios.create({ baseURL: `${config.API}/user` });

const getToken = async (header: string) => {
  const token = header.split(" ")[1];
  if (!token) return;
  const response = await refreshToken(localStorage.refresh_token);
  localStorage.refresh_token = response?.refresh_token;
  return response;
};

userApi.interceptors.response.use(
  async (e) => {
    return e;
  },
  async (error) => {
    console.log({ error });
    const originalRequest = error.config;

    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;

      try {
        const newAccessToken = await getToken(
          originalRequest.headers.Authorization
        );
        originalRequest.headers[
          "Authorization"
        ] = `Bearer ${newAccessToken?.access_token}`;
        return axios(originalRequest);
      } catch (refreshToken) {
        return Promise.reject(refreshToken);
      }
    }
    return Promise.reject(error);
  }
);

export default userApi;
