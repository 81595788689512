const walletMessages = {
  ru: {
    page_title: "Кошелек",
  },
  en: {
    page_title: "Wallet",
  },
};

export default walletMessages;
