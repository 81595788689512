import { observer } from "mobx-react";
import { FC, useRef, useState } from "react";
import styled from "styled-components";
import { useTasksStoreContext } from "./tasks.store";
import { useUserStoreContext } from "../user";
import newTaskAPI from "./task.api";
import { useTranslation } from "react-i18next";
import { message } from "antd";
import TaskModel from "./task.model";

const TaskFieldContainer = styled.div<{ focused: string }>`
  width: calc(100% - 30px);
  border-radius: 8px;
  background: ${({ focused }) => (focused === "true" ? "#fff" : "#fafafab0")};
  padding: 15px;
  display: flex;
  transition: background 0.2s;
  margin-top: auto;
  cursor: text;
  &:hover {
    background: ${({ focused }) => (focused === "true" ? "#fff" : "#fafafaea")};
  }
`;

const InputField = styled.input`
  width: 100%;
  font-size: 16px;
  border: none;
  background: transparent;
  &::placeholder {
    user-select: none;
  }
`;

const SuggestText = styled.p`
  margin: 0;
  padding: 0;
  font-size: 14px;
  text-align: center;
  opacity: 0;
  transition: opacity 0.2s;
  cursor: default;

  &.active {
    opacity: 0.6;
  }
`;

const CreateTaskField: FC = observer(() => {
  const [creatingTask, setCreatingTask] = useState<boolean>(false);
  const inputRef: any = useRef(null);
  const [text, setText] = useState<string>("");
  const { tasks, setTasks, selectedTaskGroup } = useTasksStoreContext();
  const { access_token } = useUserStoreContext();
  const { t } = useTranslation("tasks");

  const handleKey = async (key: string) => {
    if (key === "Enter") {
      if (!text.trim()) return;
      const task = await newTaskAPI.create(
        text,
        access_token,
        selectedTaskGroup !== "all" && selectedTaskGroup !== "today"
          ? selectedTaskGroup.id
          : undefined
      );
      message.success(t("task_created"));
      setTasks(
        tasks.concat(task).sort((a: TaskModel, b: TaskModel) => {
          if (b.deadline && a.deadline) {
            return (
              new Date(b.deadline).getTime() - new Date(a.deadline).getTime()
            );
          }

          if ((!a.deadline && b.deadline) || (a.deadline && !b.deadline)) {
            return a.deadline ? -1 : 1;
          }

          return a.id - b.id;
        })
      );
      setText("");
    }
  };

  return (
    <>
      <TaskFieldContainer
        focused={creatingTask.toString()}
        onFocus={() => setCreatingTask(true)}
        onBlur={() => setCreatingTask(false)}
        onClick={() => inputRef.current.focus()}
      >
        <InputField
          ref={inputRef}
          placeholder={
            creatingTask
              ? t("input_task_text")
              : selectedTaskGroup === "all" || selectedTaskGroup === "today"
              ? t("create_task")
              : t("create_task_group", { title: selectedTaskGroup.title })
          }
          maxLength={255}
          value={text}
          onChange={({ target }) => setText(target.value)}
          onKeyUp={(e) => handleKey(e.key)}
        />
      </TaskFieldContainer>
      <SuggestText className={creatingTask && text.length > 0 ? "active" : ""}>
        {t("suggest_add_task")}
      </SuggestText>
    </>
  );
});

export default CreateTaskField;
