import { action, makeObservable, observable } from "mobx";
import { createContext, useContext } from "react";
import TaskModel from "./task.model";
import TaskGroup from "./taskgroup.model";

export class TasksStore {
  tasks: TaskModel[] = [];
  taskGroups: TaskGroup[] = [];
  selectedTaskGroup: TaskGroup | "all" | "today" = "all";
  groupsLoaded: boolean = false;
  tasksLoaded: boolean = false;
  detailTask: TaskModel = {} as TaskModel;
  constructor() {
    makeObservable(this, {
      tasks: observable,
      tasksLoaded: observable,
      detailTask: observable,
      setTasks: action.bound,
      taskGroups: observable,
      selectedTaskGroup: observable,
      setTasksLoaded: action.bound,
      setDetailTask: action.bound,
      setGroupsLoaded: action.bound,
      groupsLoaded: observable,
      setSelectedTaskGroup: action.bound,
      setTaskGroups: action.bound,
      syncTasksWithGroups: action.bound,
    });
  }

  setDetailTask(task: TaskModel) {
    this.detailTask = task;
  }

  setTasks(tasks: TaskModel[]) {
    this.tasks = tasks.slice().sort((a: TaskModel, b: TaskModel) => {
      if (a.completed && !b.completed) return 1;
      if (!a.completed && b.completed) return -1;
      if (b.deadline && a.deadline) {
        const deadlineA = Math.abs(new Date(a.deadline).getTime() - Date.now());

        const deadlineB = Math.abs(new Date(b.deadline).getTime() - Date.now());
        return deadlineA - deadlineB;
      }

      if ((!a.deadline && b.deadline) || (a.deadline && !b.deadline)) {
        return a.deadline ? -1 : 1;
      }

      return a.id - b.id;
    });
    this.syncTasksWithGroups();
  }

  setTaskGroups(taskGroups: TaskGroup[]) {
    this.taskGroups = taskGroups;
  }

  setSelectedTaskGroup(taskGroup: "all" | "today" | TaskGroup) {
    this.selectedTaskGroup = taskGroup;
  }

  setTasksLoaded(bool: boolean) {
    this.tasksLoaded = bool;
  }

  setGroupsLoaded(bool: boolean) {
    this.groupsLoaded = bool;
  }

  syncTasksWithGroups() {
    // Clear all tasks in taskGroups
    this.taskGroups.forEach((group) => (group.tasks = []));

    // Add tasks to taskGroups
    this.tasks.forEach((task) => {
      if (task.group?.id !== undefined) {
        const group = this.taskGroups.find(
          (group) => group.id === task.group?.id
        );
        if (group) {
          group.tasks.push(task); // Simply push the task to the group's tasks array
        }
      }
    });
  }
}

export const tasksStore = new TasksStore();
export const tasksStoreContext = createContext(tasksStore);
export const useTasksStoreContext = () => useContext(tasksStoreContext);
