import { AuthLayout, Button, Input, config } from "../../../shared";
import { observer } from "mobx-react";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import logo from "../../../shared/brand/logo.svg";
import { AuthLink } from "../ui";
import recoveryApi from "./recovery.api";
import Links from "../ui/Links";
import Inputs from "../ui/Inputs";
import Form from "../ui/Form";
import WelcomeMessage from "../ui/WelcomeMessage";
import { message } from "antd";

const RecoveryPage: FC = observer(() => {
  const { t } = useTranslation("auth");
  const [btnEnabled, setBtnEnabled] = useState<boolean>(true);

  const onClickButton = async () => {
    if (!email.trim()) {
      return message.error(t("form_required"));
    }
    const { response, status } = await recoveryApi.recovery(email);
    setBtnEnabled(false);
    if (status !== "ok") {
      message.error(t(response));
      setBtnEnabled(true);
    } else {
      message.success(t(response));
    }
  };

  const [email, setEmail] = useState<string>("");

  return (
    <AuthLayout title={t("recovery_page_title")}>
      <Form id="recoveryForm">
        <img src={logo} alt="Voronin Lab Logo" />
        <WelcomeMessage>{t("recovery_welcome_message")}</WelcomeMessage>
        <Inputs>
          <Input
            type="text"
            placeholder={t("recovery_email_text")}
            value={email}
            onChange={({ target }) => setEmail(target.value)}
          />
        </Inputs>
        <Button disabled={!btnEnabled} onClick={onClickButton}>
          {t("recovery_text")}
        </Button>
        <Links>
          <AuthLink
            to={`/${config.getPrefixedPath(config.prefixes.AUTH)}/login`}
          >
            {t("login_text")}
          </AuthLink>
        </Links>
      </Form>
    </AuthLayout>
  );
});

export default RecoveryPage;
