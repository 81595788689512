import { config } from "../../shared";
import { RouteObject } from "react-router-dom";
import WalletPage from "./wallet.page";

const prefix = config.getPrefixedPath(config.prefixes.WALLET);

const walletRouter: RouteObject[] = [
  {
    path: `${prefix}`,
    element: <WalletPage />,
  },
];

export default walletRouter;
