import styled from "styled-components";

const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  padding-left: 25px;
  padding-right: 25px;

  img {
    width: 48px;
    margin: 0 auto;
    display: block;
  }

  @media(max-width: 380px) {
    padding-left: 15px;
    padding-right: 15px;
  }
`;

export default Form;