import styled from "styled-components";

const DualInputs = styled.div`
  display: flex;
  gap: 8px;
  @media(max-width: 420px) {
    flex-direction: column;
    gap: 14px;
  }
`;

export default DualInputs