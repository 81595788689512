import { FC, useEffect, useState } from "react";
import styled from "styled-components";
import logo from "../brand/logo.svg";
import { useUserStoreContext } from "../../entities/user";
import { useTranslation } from "react-i18next";
import { i18n } from "../i18n";
import { changeLanguage } from "i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse, faTasks } from "@fortawesome/free-solid-svg-icons";
import UserSidebar from "../../entities/user/user.sidebar";
import { Link, useNavigate } from "react-router-dom";
import { useSidebarStoreContext } from "../stores/sidebar.store";
import { SidebarTabs } from "../stores/sidebar.store";
import appApi from "../api/appApi";
import config from "../config";

const SidebarContainer = styled.div`
  height: calc(100% - 40px);
  min-width: 280px;
  padding: 20px;
  border-right: 1px solid #eee;
  display: flex;
  flex-direction: column;
  @media (max-width: 1000px) {
    width: calc(100% - 40px);
    max-width: none;
    display: none;
  }
`;

const Logo = styled.div`
  display: flex;
  gap: 20px;
  color: black;
  align-items: center;
  font-weight: bold;
  margin-bottom: 30px;
  img {
    border: 1px solid #eee;
    border-radius: 8px;
  }
  div {
    display: flex;
    flex-direction: column;
    gap: 5px;
    max-width: 200px;
    p {
      margin: 0;
      padding: 0;
    }
    span {
      font-weight: 400;
      font-size: 14px;
      overflow: hidden;
      line-break: normal;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 100%;
    }
  }
`;

const SidebarButton = styled(Link)`
  text-decoration: none;
  border-radius: 8px;
  background: transparent;
  display: flex;
  align-items: center;
  height: 42px;
  padding: 0px 15px;
  gap: 14px;
  font-size: 14px;
  cursor: pointer;
  user-select: none;
  transition: background 0.2s;
  color: #444;
  svg {
    width: 16px;
    fill: #444;
  }
  &:hover {
    background: rgba(0, 0, 0, 0.03);
    color: #222;
    svg {
      fill: #222;
    }
  }
  &.active {
    background: #408ae9;
    color: white;
    svg {
      fill: white;
    }
    span {
      /* display: none; */
    }
  }

  span {
    margin-left: auto;
    /* background: #7070701f; */
    padding: 2px 5px;
    border-radius: 5px;
    color: #a8a8a8;
    font-weight: 500;
  }
`;

const FooterLinks = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FooterLink = styled.p`
  color: #888;
  font-size: 13px;
  margin-bottom: 0;
  transition: color 0.2s;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
    color: #444;
  }
`;
const ChangeLanguage = styled(FooterLink)``;

const Header = styled.header`
  position: sticky;
  top: 0px;
  min-height: 60px;
  background: white;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
  @media (min-width: 1000px) {
    display: none;
  }
`;

const Sidebar: FC = () => {
  const { user } = useUserStoreContext();
  const { setActiveTab, activeTab } = useSidebarStoreContext();
  const navigate = useNavigate();
  const [version, setVersion] = useState<string>("");

  const { t } = useTranslation("sidebar");

  const toggleLanguage = () => {
    localStorage.language = i18n.language !== "en" ? "en" : "ru";
    changeLanguage(i18n.language !== "en" ? "en" : "ru");
  };

  const redirect = (route: string, tab: SidebarTabs) => {
    navigate(`/app${route}`);
    setActiveTab(tab);
  };

  useEffect(() => {
    if (!version) {
      appApi.get("version").then((e) => {
        if (e.status < 300) setVersion(e.data.response);
      });
    }
  });

  return (
    <>
      <Header>
        <SidebarButton
          to={"/app/main"}
          className={activeTab === SidebarTabs.DESKTOP ? "active" : ""}
          onClick={() => redirect("/main", SidebarTabs.DESKTOP)}
        >
          <FontAwesomeIcon icon={faHouse} />
          {t("desktop")}
        </SidebarButton>
        <SidebarButton
          to={"/app/tasks"}
          className={activeTab === SidebarTabs.TASKS ? "active" : ""}
          onClick={() => redirect("/tasks", SidebarTabs.TASKS)}
        >
          <FontAwesomeIcon icon={faTasks} />
          {t("tasks")}
        </SidebarButton>
      </Header>
      <SidebarContainer>
        <Logo>
          <img src={logo} alt="Voronin Lab Logotype" />
          <div>
            <p>Voronin Lab</p>
            <span
              title={`${user.first_name} ${user.last_name} (${user.email})`}
            >
              {user.email}
            </span>
          </div>
        </Logo>

        <SidebarButton
          to={"/app/main"}
          className={activeTab === SidebarTabs.DESKTOP ? "active" : ""}
          onClick={() => redirect("/main", SidebarTabs.DESKTOP)}
        >
          <FontAwesomeIcon icon={faHouse} />
          {t("desktop")}
        </SidebarButton>
        {/* <SidebarButton
        to={"/app/wallet"}
        className={activeTab === SidebarTabs.WALLET ? "active" : ""}
        onClick={() => redirect("/wallet", SidebarTabs.WALLET)}
      >
        <FontAwesomeIcon icon={faWallet} />
        {t("wallet")}
      </SidebarButton> */}
        <SidebarButton
          to={"/app/tasks"}
          className={activeTab === SidebarTabs.TASKS ? "active" : ""}
          onClick={() => redirect("/tasks", SidebarTabs.TASKS)}
        >
          <FontAwesomeIcon icon={faTasks} />
          {t("tasks")}
        </SidebarButton>
        {/* <SidebarButton
        to={"/app/users"}
        className={activeTab === SidebarTabs.USERS ? "active" : ""}
        onClick={() => redirect("/users", SidebarTabs.USERS)}
      >
        <FontAwesomeIcon icon={faUserGroup} />
        {t("users")}
      </SidebarButton>
      <SidebarButton
        to={"/app/projects"}
        className={activeTab === SidebarTabs.PROJECTS ? "active" : ""}
        onClick={() => redirect("/projects", SidebarTabs.WALLET)}
      >
        <FontAwesomeIcon icon={faDiagramProject} />
        {t("projects")}
      </SidebarButton>
      <SidebarButton
        to={"/app/messages"}
        className={activeTab === SidebarTabs.MESSAGES ? "active" : ""}
        onClick={() => redirect("/messages", SidebarTabs.MESSAGES)}
      >
        <FontAwesomeIcon icon={faComment} />
        {t("messages")}
      </SidebarButton>
      <SidebarButton
        to={"/app/notes"}
        className={activeTab === SidebarTabs.NOTES ? "active" : ""}
        onClick={() => redirect("/notes", SidebarTabs.NOTES)}
      >
        <FontAwesomeIcon icon={faNoteSticky} />
        {t("notes")}
      </SidebarButton> */}

        <UserSidebar />
        <FooterLinks>
          <ChangeLanguage onClick={toggleLanguage}>
            {t("switch_language")}
          </ChangeLanguage>
          <FooterLink onClick={() => navigate("/version")}>
            {version} / {config.client_version}
          </FooterLink>
        </FooterLinks>
      </SidebarContainer>
    </>
  );
};

export default Sidebar;
