import { authApi } from "../../../shared";
import { CHResponse } from "../../../types";

class RecoveryApi {
  async recovery(email: string) {
    let status: "error" | "ok", resp;
    try {
      const response = await authApi.post<CHResponse<string>>(
        "/requestRecovery",
        { email }
      );
      status = response.data.type;
      resp = response.data.response;
    } catch (e: any) {
      status = "error";
      resp = e.response.data.response;
    }
    return { status, response: resp };
  }
}
const recoveryApi = new RecoveryApi();
export default recoveryApi;
