import {
  SidebarTabs,
  useSidebarStoreContext,
} from "../../shared/stores/sidebar.store";
import MainLayout from "../../shared/ui/MainLayout";
import { observer } from "mobx-react";
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";

const MainPage: FC = observer(() => {
  const { t } = useTranslation("main");
  const { setActiveTab } = useSidebarStoreContext();
  useEffect(() => {
    setActiveTab(SidebarTabs.DESKTOP);
  });

  // const { access_token } = useUserStoreContext();

  // const [notifications, setNotifications] = useState<any[]>([]);

  // useEffect(() => {
  //   if (access_token) {
  //     const response = axios.get("http://localhost:4000/api/notification", {
  //       headers: { Authorization: `Bearer ${access_token}` },
  //     });
  //     response.then(({ data }) => {
  //       setNotifications(
  //         data.response
  //           .sort((a: any, b: any) => a.id - b.id)
  //           .map((e: any) => ({
  //             id: e.id,
  //             text: e.text,
  //             date: e.created_at,
  //             checked: e.checked,
  //           }))
  //       );
  //     });
  //   }
  //   const socket = io("ws://localhost:4000/notifications");
  //   socket.disconnect();
  //   socket.connect();
  //   socket.on("connect", () => {
  //     console.log("Connected notification websocket server");
  //   });

  //   socket.on(`new notification 2`, (data) => {
  //     console.log(data);
  //     const arr = {
  //       id: data.id,
  //       text: data.text,
  //       date: data.created_at,
  //       checked: data.checked,
  //     };
  //     setNotifications((prev) => prev.concat(arr));
  //   });
  // }, [access_token]);

  // const hovered = (id: number) => {
  //   setNotifications((prev) =>
  //     prev.map((item) => (item.id === id ? { ...item, checked: true } : item))
  //   );
  // };

  return (
    <MainLayout title={t("page_title")}>
      {/* {JSON.stringify(user)}
      <br />
      <b>
        Непрочитанных уведомлений:{" "}
        {notifications.filter((e) => !e.checked).length}
      </b>
      {notifications.map((e) => (
        <div key={Math.random() * 1000} onMouseEnter={() => hovered(e.id)}>
          {new Date(e.date).toLocaleString()}: {e.text}{" "}
          <i>({e.checked ? "просмотрено" : "не просмотрено"})</i>
        </div>
      ))} */}
    </MainLayout>
  );
});

export default MainPage;
