import { Checkbox } from "antd";
import { observer } from "mobx-react";
import { FC, useEffect, useState } from "react";
import styled from "styled-components";
import TaskModel from "./task.model";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import newTaskAPI from "./task.api";
import { useUserStoreContext } from "../user";
import { useTasksStoreContext } from "./tasks.store";
import { useTranslation } from "react-i18next";

const TaskContainer = styled.div`
  display: flex;
  border-bottom: 1px solid #eee;
  width: calc(100% - 20px);
  padding: 10px;
  background: white;
  border-radius: 8px;
  gap: 10px;
  user-select: none;
  align-items: flex-start;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  p {
    margin: 0;
    padding: 0;
  }

  span {
    font-size: 0.8em;
    color: #888;
    word-break: break-all; 
    overflow-wrap: break-word;
    hyphens: auto; 
  }
`;

const DeadlineCounter: React.FC<{ deadline: Date; task: TaskModel }> = ({
  deadline,
  task,
}) => {
  const [timeRemaining, setTimeRemaining] = useState<string>("");
  const { t } = useTranslation("tasks");

  const getWordForm = (
    number: number,
    one: string,
    two: string,
    many: string
  ) => {
    const lastDigit = number % 10;
    const lastTwoDigits = number % 100;

    if (lastTwoDigits >= 11 && lastTwoDigits <= 19) {
      return many;
    }

    if (lastDigit === 1) {
      return one;
    }

    if (lastDigit >= 2 && lastDigit <= 4) {
      return two;
    }

    return many;
  };

  const updateTime = () => {
    const currentTime = task.completed
      ? new Date(task.completed_at)
      : new Date();
    const timeDiff = deadline.getTime() - currentTime.getTime();

    if (timeDiff > 0) {
      const years = Math.floor(timeDiff / (1000 * 60 * 60 * 24 * 365));
      const months = Math.floor(
        (timeDiff % (1000 * 60 * 60 * 24 * 365)) / (1000 * 60 * 60 * 24 * 30)
      );
      const days = Math.floor(
        (timeDiff % (1000 * 60 * 60 * 24 * 30)) / (1000 * 60 * 60 * 24)
      );
      const hours = Math.floor(
        (timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));

      if (years > 0) {
        setTimeRemaining(
          `${
            task.completed ? t("completed_advance") : t("more_left")
          } ${years} ${getWordForm(
            years,
            t("years.one"),
            t("years.two"),
            t("years.many")
          )} ${months} ${getWordForm(
            months,
            t("months.one"),
            t("months.two"),
            t("months.many")
          )}`
        );
      } else if (months > 0) {
        setTimeRemaining(
          `${
            task.completed ? t("completed_advance") : t("more_left")
          } ${months} ${getWordForm(
            months,
            t("months.one"),
            t("months.two"),
            t("months.many")
          )} ${days} ${getWordForm(
            days,
            t("days.one"),
            t("days.two"),
            t("days.many")
          )}`
        );
      } else if (days > 0) {
        setTimeRemaining(
          `${
            task.completed ? t("completed_advance") : t("more_left")
          } ${days} ${getWordForm(
            days,
            t("days.one"),
            t("days.two"),
            t("days.many")
          )} ${hours} ${getWordForm(
            hours,
            t("hours.one"),
            t("hours.two"),
            t("hours.many")
          )}`
        );
      } else if (hours > 0) {
        setTimeRemaining(
          `${
            task.completed ? t("completed_advance") : t("more_left")
          } ${hours} ${getWordForm(
            hours,
            t("hours.one"),
            t("hours.two"),
            t("hours.many")
          )} ${minutes} ${getWordForm(
            minutes,
            t("minutes.one"),
            t("minutes.two"),
            t("minutes.many")
          )}`
        );
      } else {
        setTimeRemaining(
          `${
            task.completed ? t("completed_advance") : t("more_left")
          } ${minutes} ${getWordForm(
            minutes,
            t("minutes.one"),
            t("minutes.two"),
            t("minutes.many")
          )}`
        );
      }
    } else {
      const overdueTime = new Date(Math.abs(timeDiff));
      const years = Math.floor(
        overdueTime.getTime() / (1000 * 60 * 60 * 24 * 365)
      );
      const months = Math.floor(
        (overdueTime.getTime() % (1000 * 60 * 60 * 24 * 365)) /
          (1000 * 60 * 60 * 24 * 30)
      );
      const days = Math.floor(
        (overdueTime.getTime() % (1000 * 60 * 60 * 24 * 30)) /
          (1000 * 60 * 60 * 24)
      );
      const hours = Math.floor(
        (overdueTime.getTime() % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor(
        (overdueTime.getTime() % (1000 * 60 * 60)) / (1000 * 60)
      );

      let overdueMessage = "";

      if (years > 0) {
        overdueMessage = `${years} ${getWordForm(
          years,
          t("years.one"),
          t("years.two"),
          t("years.many")
        )} ${months} ${getWordForm(
          months,
          t("months.one"),
          t("months.two"),
          t("months.many")
        )}`;
      } else if (months > 0) {
        overdueMessage = `${months} ${getWordForm(
          months,
          t("months.one"),
          t("months.two"),
          t("months.many")
        )} ${days} ${getWordForm(
          days,
          t("days.one"),
          t("days.two"),
          t("days.many")
        )}`;
      } else if (days > 0) {
        overdueMessage = `${days} ${getWordForm(
          days,
          t("days.one"),
          t("days.two"),
          t("days.many")
        )} ${hours} ${getWordForm(
          hours,
          t("hours.one"),
          t("hours.two"),
          t("hours.many")
        )}`;
      } else if (hours > 0) {
        overdueMessage = `${hours} ${getWordForm(
          hours,
          t("hours.one"),
          t("hours.two"),
          t("hours.many")
        )} ${minutes} ${getWordForm(
          minutes,
          t("minutes.one"),
          t("minutes.two"),
          t("minutes.many")
        )}`;
      } else {
        overdueMessage = `${minutes} ${getWordForm(
          minutes,
          t("minutes.one"),
          t("minutes.two"),
          t("minutes.many")
        )}`;
      }

      setTimeRemaining(`${t("overdue")} ${overdueMessage}`);
      // clearInterval(intervalId);
    }
  };
  useEffect(() => {
    updateTime();
    const intervalId = setInterval(updateTime, 60000);
    return () => clearInterval(intervalId);
  });

  return <>• {timeRemaining} </>;
};

const TaskLine: FC<{ task: TaskModel; onlyView?: boolean }> = observer(
  ({ task, onlyView }) => {
    const [checked, setChecked] = useState<boolean>(task.completed);
    const { access_token } = useUserStoreContext();
    const { detailTask, setDetailTask } = useTasksStoreContext();
    const { t } = useTranslation("tasks");
    const handleContainerClick = () => {
      if (!onlyView) {
        if (detailTask.id === task.id) {
          setDetailTask({} as TaskModel);
        } else {
          setDetailTask(task);
        }
      }
    };

    const handleCheckboxClick = async (event: CheckboxChangeEvent) => {
      if (!onlyView) {
        event.stopPropagation();
        setChecked(event.target.checked);
        task.completed = event.target.checked;
        task.completed_at = new Date();
        await newTaskAPI.completeTask(task, access_token);
      }
    };

    return (
      <TaskContainer onClick={handleContainerClick}>
        <Checkbox
          checked={checked}
          onChange={handleCheckboxClick}
          onClick={(e) => e.stopPropagation()}
        />
        <Content>
          <p>{task.text}</p>
          <span>
            {task.group ? `${task.group.title}` : t("all_tasks")}{" "}
            {task.deadline &&
              `• ${new Date(task.deadline).toLocaleDateString()} ${
                new Date(task.deadline).getHours() !== 0 ||
                new Date(task.deadline).getMinutes() !== 0
                  ? `${new Date(task.deadline)
                      .getHours()
                      .toString()
                      .padStart(2, "0")}:${String(
                      new Date(task.deadline).getMinutes()
                    ).padStart(2, "0")} `
                  : ""
              }`}
            {task.deadline && (
              <DeadlineCounter deadline={new Date(task.deadline)} task={task} />
            )}
            {task.description && `• ${task.description}`}
          </span>
        </Content>
      </TaskContainer>
    );
  }
);

export default TaskLine;
