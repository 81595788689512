const authMessages = {
  ru: {
    login_page_title: "Авторизация / Voronin Lab",
    login_welcome_message: "Войдите в Voronin Lab",
    login_email_text: "Почтовый адрес",
    login_password_text: "Пароль",
    signup_page_title: "Регистрация / Voronin Lab",
    signup_welcome_message: "Зарегистрируйтесь в Voronin Lab",
    signup_first_name_text: "Имя",
    signup_last_name_text: "Фамилия",
    signup_email_text: "Почтовый адрес",
    signup_password_text: "Пароль (длиннее 8 символов)",
    signup_button_text: "Зарегистрироваться",
    signup_login_text: "Уже зарегистрированы?",
    login_text: "Войти",
    signup_text: "Зарегистрироваться",
    switch_language: "Switch to English",
    recovery_password: "Забыли пароль?",

    recovery_page_title: "Восстановление пароля / Voronin Lab",
    recovery_welcome_message: "Восстановление пароля",
    recovery_email_text: "Почтовый адрес",
    recovery_text: "Восстановить",

    refresh_token_not_found: "Токен авторизации не найден",
    user_not_found: "Пользователь не найден",
    wrong_password: "Неправильный пароль",
    success_login: "Вы успешно авторизовались",
    form_required: "Заполните форму",

    success_signup: "Вы успешно зарегистрировались",
    user_already_exists: "Пользователь уже существует",

    recovery_code_sended: "Код восстановления отправлен",
  },
  en: {
    login_page_title: "Sign in / Voronin Lab",
    login_welcome_message: "Sign in to Voronin Lab",
    login_email_text: "Email address",
    login_password_text: "Password",
    login_text: "Sign In",
    signup_text: "Sign Up",
    signup_page_title: "Sign up / Voronin Lab",
    signup_welcome_message: "Sign Up to Voronin Lab",
    signup_first_name_text: "First Name",
    signup_last_name_text: "Last name",
    signup_email_text: "Email",
    signup_password_text: "Password (8+ characters)",
    signup_button_text: "Sign Up",
    signup_login_text: "Already signed up?",
    recovery_password: "Forgot your password?",
    switch_language: "Перевести на русский",

    recovery_page_title: "Recovery password / Voronin Lab",
    recovery_welcome_message: "Recovery password",
    recovery_email_text: "Email address",
    recovery_text: "Recovery",

    refresh_token_not_found: "Refresh token not found",
    user_not_found: "User not found",
    wrong_password: "Wrong password",
    success_login: "Successfull",
    form_required: "Fill the form",
    success_signup: "Successfull",
    user_already_exists: "User with email already exists",

    recovery_code_sended: "Recovery code sended",
  },
};

export default authMessages;
